/* Loading.css */
.loading-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    padding: 10px;
    background-color: #FFFFFF; 
}
  


.progress-bar-custom {
    height: 50px;  /* Adjust as needed */
    width: 100%;
    margin-top: 10px;
    border-radius: 10px;
    position: relative;
    border: none; /* Remove border */
    background-color: #007BFF;
}

.loading-message {
    font-size: 22px;
    color: #555;
    font-weight: 500;
    text-align: center;
    margin-bottom: 20px;
}



.project-created-message {
    font-size: 22px;
    color: #555;
    font-weight: 500;
    text-align: center;
    margin-bottom: 20px;
}

.view-project-button {
    font-size: 1rem;
    padding: 0.75rem 1.5rem;
    border-radius: 20px;
    color: #fff; /* White color */
    background-color: #3f51b5; /* Indigo color */
    border: none;
    transition: background-color 0.3s ease;
    padding: 10px;
    width: 100%;

}

.view-project-button:hover {
    background-color: #303f9f; /* Darker Indigo */
}



.begin-setup-button {
    font-size: 1rem;
    padding: 0.75rem 1.5rem;
    border-radius: 20px;
    color: #fff; /* White color */
    background-color: #3f51b5; /* Indigo color */
    border: none;
    transition: background-color 0.3s ease;
    padding: 10px;
    width: 100%;

}

.begin-setup-button:hover {
    background-color: #303f9f; /* Darker Indigo */
}


