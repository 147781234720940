.projects-container {
    padding: 10px;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 10px;
    justify-content: center;
    width: 100%;
}

.project-card-wrapper {
    display: flex;
    flex-direction: column;
    gap: 10px;
    width: 30%;
    min-width: 300px;
    max-width: 400px;
    overflow: hidden;
    padding: 1rem;
}

.project-link {
    text-decoration: none;
    color: inherit;
}

.load-more-button {
    width: 100%;
    padding: 10px;
    background-color: #007bff;
    color: #ffffff;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    margin-top: 10px;
}

.load-more-button:hover {
    background-color: #0056b3;
}



/* Styling for the modal header */
.modal-header {
    background-color: #007bff;  /* Blue color taken from .load-more-button for consistency */
    color: white;
}

.modal-title {
    font-weight: bold;
}



.industry-form-container {
    padding: 20px 0;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    
}

.industry-form-card {
    margin-bottom: 15px;
}

.industry-form-card label {
    font-weight: bold;
    margin-bottom: 10px;
}

.industry-form-card select {
    width: 100%;
    padding: 5px 10px;
    border: 1px solid #ccc;
    border-radius: 4px;
}


/* Styling for the Autocomplete component */
.mui-autocomplete {
    width: 100%;
    max-width: 400px;
}

/* Styling for the TextField component inside Autocomplete */
.mui-autocomplete .MuiOutlinedInput-root {
    padding: 5px 10px;
    border-radius: 4px;
}

.mui-autocomplete .MuiOutlinedInput-notchedOutline {
    border: 1px solid #ccc;
}

.mui-autocomplete .MuiInputLabel-outlined {
    transform: translate(14px, 16px) scale(1);
}

.mui-autocomplete .MuiInputLabel-outlined.MuiInputLabel-shrink {
    transform: translate(14px, -6px) scale(0.75);
}

.mui-autocomplete .MuiAutocomplete-inputRoot {
    padding: 5px 10px;
}

.mui-autocomplete .MuiAutocomplete-endAdornment {
    right: 10px;
}
