/* ExistingJobs.css */
.jobs-container {
    padding: 10px 15%;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 10px;
    justify-content: flex-start;
    width: 100%;
    background-color: #f5f8fc;
}

.job-card-wrapper {
    flex-basis: calc(33.33% - 20px); 
    max-width: calc(33.33% - 20px);
    box-sizing: border-box; 
    margin-top: 10px;
    margin-right: 10px;
    margin-bottom: 10px;
}

.job-overview-card {
    height: 100%;
    display: flex;
    flex-direction: column;
    cursor: pointer;
}


.job-link {
    text-decoration: none;
    color: inherit;
}

.load-more-button {
    width: 100%;
    padding: 10px;
    background-color: #007bff;
    color: #ffffff;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    margin-top: 10px;
}

.load-more-button:hover {
    background-color: #0056b3;
}



.centered-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
}

.review-header {
    text-align: center;
    margin-bottom: 20px;
}

.review-list {
    list-style-type: none;
    padding-left: 0;
    
}

.review-list li {
    border-bottom: 1px solid #E5E5E5;
    padding: 10px 0;
    font-size: 16px;
    
}



/* Modal Styling */


.new-job-modal-content {
    border-radius: 15px;
    border: none;
    box-shadow: 0 4px 8px rgba(0, 123, 255, 0.2);
    width: 100%;
}

.new-job-modal-dialog {
    margin: auto;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 1260px; /* Adjust the width to accommodate the cards */
}


.new-job-modal-header, .new-job-modal-footer {
    border: none;
    padding: 15px 20px;
}

.new-job-modal-header .close {
    color: #007BFF;
    font-size: 24px;
}

.new-job-modal-title {
    color: #007BFF;
    font-weight: 500;
    font-size: 1.5rem;
}

.new-job-modal-body {
    padding: 20px;
}

.new-job-modal-footer .btn-primary {
    background-color: #007BFF;
    border: none;
    transition: background-color 0.3s ease-in-out;
    padding: 10px 20px;
}

.new-job-modal-footer .btn-primary:hover {
    background-color: #0056b3;
}

.new-job-modal-footer .btn-secondary {
    background-color: #f8f9fa;
    border: none;
    transition: background-color 0.3s ease-in-out;
    padding: 10px 20px;
}

.new-job-modal-footer .btn-secondary:hover {
    background-color: #e2e6ea;
}


.custom-modal-dialog {
    width: 90vw;
    max-width: 100%;
}



.industry-description textarea, .job-title textarea, .review textarea {
    width: 100%;
    border: none;
    outline: none;
    padding: 0;
    margin: 0;
    font-size: 16px;
    font-family: inherit;
    color: inherit;
    background-color: transparent;
    transition: border-bottom 0.3s ease-in-out;
    margin-bottom: 15px;
    resize: none;
    overflow-y: auto;
    height: auto;
    border: 1px solid #E5E5E5;
    border-radius: 15px;
    padding: 10px;
}

.industry-description textarea:hover, 
.job-title textarea:hover, 
.review textarea:hover,
.industry-description textarea:focus, 
.job-title textarea:focus, 
.review textarea:focus {
    border-bottom: 2px solid #0055b0;
}

.industry-description.editing textarea, 
.job-title.editing textarea, 
.review.editing textarea {
    color: #0055b0;
}