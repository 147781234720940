/* BrainstormSideBar.css */

.brainstorm-sidebar {
    max-width: 100%;
    box-sizing: border-box;
}

.sidebar-items-container {
    justify-content: space-between;
    padding: 0 10px;
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
    scroll-snap-type: y mandatory;
    margin-top: 60px;
}

.sidebar-item {
    margin: 0 5px;
    border: 1px solid #E5E5E5;
    border-radius: 15px;
    padding: 10px;
    transition: transform 0.2s ease-in-out;
    box-shadow: 0 4px 8px rgba(0, 123, 255, 0.2);
    background-color: #FFFFFF;
    text-align: center;
    cursor: pointer;
    justify-content: center;
    align-items: center;  
    display: inline-block;
    flex: none;
    width: 100px;
    max-width: 100px;
    overflow-y: scroll;
    min-height: 80px;
    max-height: 80px;
}

.sidebar-item:hover {
    background-color: #F7FAFF; 
}

.sidebar-item h3 {
    font-size: 0.8em; 
    font-weight: 600;
    color: #007BFF;
    margin: 0;
    text-align: center;
    white-space: wrap;
}

@media (max-width: 768px) {
    .sidebar-item {
        font-size: 0.8em; /* Reduce font size for smaller screens */
    }
}


.sidebar-item h3.pre-display {
    color: #888; /* Darker gray for better contrast */
}


.sidebar-item.no-data {
    background-color: #F3F6FC; /* Lighter background for no-data items */
    cursor: default;
    color: #B0B0B0; /* Muted text color for no-data items */
}


.sidebar-item.no-data h3 {
    color: #B0B0B0;
}



.sidebar-item.current {
    background-color: #F3F6FC; 
    color: #FFFFFF;
    box-shadow: 0 8px 16px rgba(0, 123, 255, 0.4);

}




.sidebar-item.current h3 {
    color: #007BFF;
}

.sidebar-item:not(.no-data):not(.current) {
    border: 1px solid #007BFF; /* Bold border for yes-data items */
    background-color: #F3F6FC; 
    cursor: pointer;
}



.sidebar-item:not(.no-data):not(.current) h3 {
    font-weight: bold;
    color: #007BFF;
    font-style: italic;

}

.sidebar-item.sidebar-item:not(.no-data):not(.current):hover {
    transform: translateY(-3px);
    background-color: #f5f5f5;

}




.metric-item {
    padding: 10px;
    border: 1px solid #E5E5E5;
    border-radius: 5px;
    margin: 15px 0;
    background-color: #f9f9f9;
}

.metric-item p {
    margin: 5px 0;
}

.brainstorm-modal {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
}

.brainstorm-modal-content {
    background-color: #fff;
    padding: 20px;
    border-radius: 10px;
    max-height: 80vh;
    position: relative;
    overflow-y: auto;
    max-height: 80vh;
    box-shadow: 0 8px 16px rgba(0, 123, 255, 0.2);
    border: 1px solid #007BFF;
    width: 40%; /* occupy 90% of the viewport width */
    transform: scale(0.95); /* Start a bit smaller */
    transition: transform 0.3s ease-in-out;
}

@media (max-width: 768px) {
    .brainstorm-modal-content {
        width: 80%; /* occupy 80% of the viewport width on smaller screens */
    }
}

@media (max-height: 500px) {
    .brainstorm-modal-content {
        max-height: 90vh;
    }
}


.brainstorm-close-button {
    position: absolute;
    top: 10px;
    right: 10px;
    font-size: 24px;
    cursor: pointer;
    color: #999; /* Slightly muted color */
    transition: color 0.2s ease-in-out;
}

.brainstorm-close-button:hover {
    color: #007BFF;
    transition: color 0.2s ease-in-out;
}

.modal-edit-icon {
    position: absolute;
    top: 10px;
    right: 40px; /* Position it to the left of the close button */
    font-size: 24px;
    cursor: pointer;
    color: #999; 
    transition: color 0.2s ease-in-out;
}

.modal-edit-icon:hover {
    color: #0056b3;
}

.modal-content-text p {
    padding-left: 25px; /* Increase spacing for the left border */
    border-color: #D1D1D1; /* Lighter blue for the border */
    border-left: #007BFF solid 5px; /* Add a left border */
    cursor: default;
}

.modal-content-text p:first-child {
    font-weight: bold;
    font-size: 1.3em;
    border-left: none;
    padding-left: 0;
    color: #007BFF;
    cursor: default;
    margin-right: 5px;
}




.modal-editable-content {
    display: flex;
    flex-direction: column;
    gap: 10px;
}

/* Updated modal editable content to match editing styles in Works.css */
.modal-edit-input, .modal-edit-textarea {
    width: 100%;
    border: none;
    outline: none;
    padding: 0;
    margin: 0;
    font-size: 16px;
    font-family: inherit;
    color: inherit;
    background-color: transparent;
    transition: border-bottom 0.3s ease-in-out;
    margin-bottom: 15px;
}


.modal-edit-input:hover, .modal-edit-textarea:hover,
.modal-edit-input:focus, .modal-edit-textarea:focus {
    border-bottom: 2px solid #0055b0;
}

.modal-edit-textarea {
    resize: none;
    overflow-y: auto;
    height: 200px;
    border: 1px solid #E5E5E5;
    border-radius: 15px;
    padding: 10px;
}


.modal-editable-content button {
    font-weight: 500; /* Semi-bold text for button */
    transition: all 0.2s ease-in-out; /* Smooth transition for all properties */
}

.modal-editable-content button:hover {
    background-color: #0056b3; /* Darker blue on hover */
    transform: translateY(-3px); /* Slight move up on hover */
    box-shadow: 0 4px 6px rgba(0,0,0,0.1); /* Drop shadow on hover */
}

.modal-editable-content button:active {
    transform: translateY(0); /* Reset position on click */
    box-shadow: none; /* No shadow on click */
}

.metric-item .modal-edit-icon {
    color: #007BFF; 
    transition: color 0.2s ease-in-out;
    cursor: pointer;
    font-size: 18px;
    margin-left: 5px;
}

.metric-item .modal-edit-icon:hover {
    color: #0056b3;
}


.tippy-box {
    background-color: #616161;
    color: white;
    border-radius: 4px;
    box-shadow: 0 2px 10px rgba(0,0,0,0.1);
    padding: 6px 8px;
    font-size: 12px;
    opacity: 0;
    transform: translateY(10px);
    transition: opacity 0.35s cubic-bezier(0.19, 1, 0.22, 1), transform 0.35s cubic-bezier(0.19, 1, 0.22, 1);
}

.tippy-box[data-state='visible'] {
    opacity: 1;
    transform: translateY(0);
    transition-delay: 0.1s; /* slight delay before tooltip appears */
}

.tippy-arrow {
    color: #616161;
}


/* Animation when modal appears */
@keyframes fadeInUp {
    0% {
        opacity: 0;
        transform: translateY(30px);
    }
    100% {
        opacity: 1;
        transform: translateY(0);
    }
}

.brainstorm-modal-content.show {
    animation: fadeInUp 0.3s forwards; /* Apply the animation */
}


@keyframes fadeOutDown {
    0% {
        opacity: 1;
        transform: translateY(0);
    }
    100% {
        opacity: 0;
        transform: translateY(30px);
    }
}

.brainstorm-modal-content.hide {
    animation: fadeOutDown 0.3s forwards;
}
