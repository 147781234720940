.project-metrics-container {
    background: #f7f7f7;
    border-radius: 10px;
    padding: 20px;
    margin: 20px 0;
    box-shadow: 0 4px 8px rgba(0, 123, 255, 0.1);
}

.metrics-list {
    list-style-type: none;
    padding: 0;
}

.metric-item {
    border: 1px solid #e5e5e5;
    border-radius: 8px;
    padding: 10px;
    margin: 10px 0;
    background: #ffffff;
}

.metric-item h3 {
    margin-top: 0;
    color: #007BFF;
}

.metric-item p {
    margin-bottom: 0;
}
