/* AiConfig.css */

.ai-config-card {
    background-color: #f9f9f9;
    border-radius: 5px;
    padding: 20px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05);
    margin-bottom: 20px;
}

.ai-config-card label {
    display: block;
    margin-bottom: 50px;  /* increased margin */
    font-weight: 600;
    color: #333;
}


.ai-config-card input[type="text"],
.ai-config-card select,
.ai-config-card textarea,
.ai-config-card input[type="range"] {
    width: 100%;
    padding: 20px 12px;
    border: 1px solid #dcdcdc;
    border-radius: 4px;
    font-size: 14px;
    color: #555;
    transition: border-color 0.3s;
}

.ai-config-card input[type="text"]:focus,
.ai-config-card select:focus,
.ai-config-card textarea:focus {
    border-color: #0073e6;
    outline: none;
}

.ai-config-card textarea {
    height: 100px;
    resize: vertical;
}

.ai-config-card input[type="range"] {
    cursor: pointer;
}

.ai-config-card span {
    display: inline-block;
    margin-left: 10px;
    font-weight: bold;
}
