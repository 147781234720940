.csv-upload-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: #FFFFFF;
    padding: 20px 40px;
    border-radius: 20px;
    transition: all 0.3s ease-in-out;
    width: 100%;
    max-width: 1100px;
}

.csv-upload-section {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    margin-bottom: 30px;
}

.csv-input {
    display: none;
}

.csv-label {
    display: inline-flex;
    align-items: center;
    border-radius: 12px;
    transition: all 0.3s ease-in-out;
    cursor: pointer;
    box-shadow: 0px 5px 15px rgba(0, 123, 255, 0.1);
}

.csv-label:hover {
    transform: scale(1.05);
    background-color: #F7FAFF;
    box-shadow: 0 10px 20px rgba(0, 123, 255, 0.2);
}

.metric-cards-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    margin-top: 20px;
    max-width: 1000px;
    width: 100%;
    gap: 20px; /* Adding gap to provide consistent spacing between cards */
}

.metric-card {
    width: calc(33.33% - 20px); /* Adjusting width for 3 cards per row minus the gap */
    margin: 0; /* Removing individual margin in favor of gap */
    border-radius: 12px;
    transition: transform 0.3s, box-shadow 0.3s;
    box-shadow: 0px 5px 15px rgba(0, 123, 255, 0.1);
    cursor: pointer;
    border: 1px solid #e6e6e6;
}


.metric-card:hover {
    background-color: #F7FAFF;
    box-shadow: 0 10px 20px rgba(0, 123, 255, 0.2);
    border: 1px solid #007BFF;
}

.metric-card.selected {
    background-color: #F7FAFF;
    box-shadow: 0 10px 20px rgba(0, 123, 255, 0.2);
    border: 1px solid #007BFF;
}

.metrics-header {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    margin-bottom: 20px;
}

.metrics-progress-container {
    position: relative;
    width: 100%;
}

.metrics-progress-bar {
    width: 100%;
    height: 20px;
    background-color: #E5E5E5;
    border-radius: 10px;
    overflow: hidden;
}

.metrics-progress-fill {
    height: 100%;
    background-color: #007BFF;
    transition: width 0.3s ease-in-out;
}

.metrics-count {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-weight: bold;
    color: #333;
}

.metrics-hint {
    margin-top: 10px;
    font-size: 0.9em;
    color: #555;
}



.metrics-counter::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border-radius: 50%;
    border: 3px solid #e6e6e6;
    box-sizing: border-box;
}

.metrics-counter::after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: calc(100% * var(--fill-factor, 0));
    background-color: #007BFF;
    border-radius: 50% 50% 50% 50%/100% 100% 0 0;
    transform-origin: 50% 100%;
    transition: height 0.3s;
}

.metric-card svg {
    margin-right: 10px;
}

/* Add a consistent hover effect to the metric cards */
.metric-card:hover {
    background-color: #F7FAFF;
    box-shadow: 0 10px 20px rgba(0, 123, 255, 0.2);
    border: 1px solid #007BFF;
}

.metric-card.selected {
    background-color: #F7FAFF;
    box-shadow: 0 10px 20px rgba(0, 123, 255, 0.2);
    border: 1px solid #007BFF;
}


.metric-card .MuiTypography-h6 {
    color: #007BFF;
    margin-left: 15px;
}

.metric-card .MuiTypography-body2 {
    margin-top: 10px;
    color: #555;
}

.metric-card input, .metric-card textarea {
    color: #0055b0;
    width: 100%;
    border: none;
    outline: none;
    padding: 0;
    margin: 0;
    font-size: 16px;
    font-family: inherit;
    background-color: transparent;
    transition: border-bottom 0.3s ease-in-out;
    margin-bottom: 15px;
}

.metric-card input:hover, .metric-card textarea:hover,
.metric-card input:focus, .metric-card textarea:focus {
    border-bottom: 2px solid #0055b0;
}

.metric-card textarea {
    resize: none;
    overflow-y: auto;
    height: 100px;
    border: 1px solid #E5E5E5;
    border-radius: 12px;
    padding: 10px;
}

.metric-card span:hover {
    transform: scale(1.1);
    cursor: pointer;
}

.metric-card .MuiSvgIcon-root {
    font-size: 1.2em;
    color: #007BFF;
    transition: color 0.3s;
}

.metric-card .MuiSvgIcon-root:hover {
    color: #0056b3;
}

.metric-card.editing .MuiSvgIcon-root {
    color: #0056b3;
}

@media (max-width: 768px) {
    .metric-cards-container {
        justify-content: center;
    }

    .csv-upload-container {
        padding: 20px;
    }

    .metric-card {
        width: 320px;
    }
}
