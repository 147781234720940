
.signup-container {
    width: 100%;
    max-width: 400px;
    margin: 0 auto;
    padding: 20px;
}

.signup-title {
    text-align: center;
    margin-bottom: 20px;
}

.signup-form .signup-label {
    display: block;
    margin-bottom: 5px;
}

.signup-form .signup-input {
    margin-bottom: 20px;
    padding: 10px;
    font-size: 16px;
}

.signup-button {
    width: 100%;
    background-color: #007bff;
    border: none;
    color: white;
    padding: 10px;
    font-size: 18px;
    cursor: pointer;
    text-align: center;
}

.signup-button:hover {
    background-color: #0069d9;
}

.signup-error {
    color: red;
    font-weight: bold;
}

.login-link {
    text-align: center;
    margin-top: 20px;
}
