.nav-brand, .nav-link {
    color: white;
    text-decoration: none;
  }
  
  .nav-link {
    margin-left: 20px;
  }
  
  button {
    color: white;
  }
  