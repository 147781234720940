/* BrainstormProject.css */


.job-info-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    margin: 0 auto; /* Center the container */
}

@media (max-width: 768px) {
    .job-info-container {
        padding: 10px;
    }
}


.sidebar-container {
    justify-content: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
}



.content-container {
    display: flex;
    flex-direction: column; /* Stack children vertically */
    justify-content: center; /* Center children vertically */
}


.continue-button {
    background-color: #007BFF;
    color: white;
    border: none;
    padding: 10px 20px; /* Added some horizontal padding */
    font-size: 16px;
    font-weight: bold;
    border-radius: 5px;
    cursor: pointer;
    box-shadow: 0 2px 5px rgba(0,0,0,0.1);
    transition: background-color 0.3s ease, transform 0.3s ease, opacity 0.3s ease;
    margin-top: 5px;
    align-self: center; /* Center the button */
    width: auto; /* Let the button size itself based on content */
    min-height: 40px;
    margin-bottom: 30px;
    width: 25%;
}


.continue-button.disabled {
    background-color: #F3F6FC;
    cursor: not-allowed;
    box-shadow: none;
    color: #B0B0B0;
    box-shadow: 0 4px 8px rgba(0, 123, 255, 0.2);

}

.continue-button:hover:not(.disabled) {
    background-color: #0056b3;
    transform: translateY(-2px); /* Slight lift on hover */
}

.continue-button:focus, .continue-button:active {
    outline: none;
    box-shadow: 0 0 0 3px rgba(0, 123, 255, 0.5); /* This provides a blue glow when the button is focused or active */
}


.sidebar-container,
.content-container {
    max-width: 1200px;
}


.dynamic-title {
    margin-bottom: 5px;
    padding: 20px 20px; /* Added some horizontal padding */
    border-radius: 15px; /* Rounded corners */
    background-color: #f5f5f5;
    color: #000000; /* White text color */
    font-size: 1.1em; /* Slightly larger font */
    animation: fadeIn 1s ease-in forwards;
    box-shadow: 0 2px 6px rgba(0, 0, 0, 0.2); /* Soft shadow for depth */
    width: 50%;
    margin-top: 5px;
    align-self: center; /* Center the button */
    width: 70%; /* Let the button size itself based on content */
    min-height: 40px;
    text-align: center;
}


@media (max-width: 768px) {
    .sidebar-container {
        padding: 10px;
        display: flex;
        flex-direction: column;
        align-items: center;
    }
    .content-container {
        padding: 10px;
        display: flex;
        flex-direction: column;
    }

    .dynamic-title {
        width: 100%;
    }
    .continue-button {
        width: 100%;
    }

}



