/* RunProjectCard.css */


.run-project-card {
    border: 1px solid #e0e0e0;
    padding: 15px;
    border-radius: 8px;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
    margin: 15px 0;
    position: relative;
    background-color: #fff;
    transition: box-shadow 0.3s ease, transform 0.3s ease;
    width: 100%
}

.run-project-card:hover {
    transform: translateY(-2px);
}


.run-project-header {
    cursor: pointer;
    display: flex;
    align-items: center;
    gap: 10px;
}

.run-project-title {
    font-weight: 500;
    margin: 0;
    font-size: 24px;
    color: #333;
}


.run-project-card button {
    margin-top: 10px; /* Add some margin for visual separation */
    margin-bottom: 20px;
    background-color: #007BFF;
    color: white;
    padding: 10px 20px;
    border-radius: 5px;
    border: none;
    cursor: pointer;
    transition: background-color 0.2s;
}

.run-project-card button:hover {
    background-color: #0056b3; /* slightly darker on hover */
}

.run-project-card button:disabled {
    background-color: #b3c2d1; /* grayed out when disabled */
    cursor: not-allowed;
}


.settings-icon {
    cursor: pointer;
    color: #007BFF; /* Adjust as needed */
}

.settings-icon:hover {
    color: #0056b3; /* slightly darker on hover */
}

.config-modal-box {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 1000;
    width: 100%;
    height: 100%;
    background-color: rgba(0,0,0,0.5); /* semi-transparent black */
    display: flex;
    justify-content: center;
    align-items: center;
}