/* SlackConfig.css */

.slack-config-card {
    background-color: #f7f7f7;
    border-radius: 5px;
    padding: 20px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    margin-bottom: 20px;
}

.slack-config-card label {
    display: block;
    margin-bottom: 15px;
    font-weight: 600;
    color: #333;
}

.slack-config-card input[type="text"] {
    width: 100%;
    padding: 8px 12px;
    border: 1px solid #dcdcdc;
    border-radius: 4px;
    font-size: 14px;
    color: #555;
    transition: border-color 0.3s;
}

.slack-config-card input[type="text"]:focus {
    border-color: #0073e6;
    outline: none;
}
