/* Industry.css */
.industry-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: center; /* This will center the cards when they don't span the entire width */
    padding: 5px;
    background-color: #FFFFFF; 
    gap: 40px; /* Adjusts the spacing between the cards */
    margin-bottom: 150px;
}

.industry-card {
    flex: 0 1 calc(25% - 10px); /* By default, cards take up 1/4 of the container minus the gap */
    max-width: calc(25% - 10px);
    min-width: calc(25% - 10px);
    margin: 0; /* Remove margin as we're using gap */
    cursor: pointer;
    transition: all 0.3s ease-in-out, transform 0.3s ease-in-out, box-shadow 0.3s ease-in-out, height 0.3s ease-in-out;
    border: 1px solid #E5E5E5;
    border-radius: 20px;
    padding: 20px;
    position: relative;
    box-shadow: 0 4px 12px rgba(0, 123, 255, 0.08);
    color: #007BFF;
    overflow: auto;
}

.industry-card.active, .industry-card:hover {
    background-color: #F7FAFF; 
    box-shadow: 0 6px 18px rgba(0, 123, 255, 0.15);
    border: 1px solid #007BFF; 
    transform: translateY(-10px);
}

.industry-card input, .industry-card textarea {
    width: 100%;
    border: none;
    outline: none;
    padding: 5px 0;
    font-size: 16px;
    font-family: inherit;
    color: inherit;
    background-color: transparent;
    margin-bottom: 2px;
    transition: border-bottom 0.3s ease-in-out;
}

.industry-card input:focus, .industry-card textarea:focus {
    border-bottom: 2px solid #007BFF;
}

/* Enhanced Hover effect for icons */
.industry-card .MuiSvgIcon-root {
    font-size: 1.0em;
    color: #007BFF;
    transition: color 0.3s ease-in-out, transform 0.3s ease-in-out;
}

.industry-card .MuiSvgIcon-root:hover {
    color: #0056b3;
    transform: scale(1.05);
}

.industry-card.editing .MuiSvgIcon-root {
    color: #0056b3;
}

.industry-card span {
    cursor: pointer;
    position: absolute;
    top: 10px;
    right: 10px;
    font-size: 1.5em;
    transition: transform 0.3s ease-in-out;
}

.industry-card span:hover {
    transform: scale(1.05);
}

.industry-loading {
    font-size: 20px;
    color: #000000;
    text-align: center;
    padding: 30px;
}

.industry-card p {
    font-size: 16px;
    line-height: 1.5;
    color: #333;
    margin-top: 10px;
}

.card-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 15px;
}

.card-header h4 {
    font-size: 18px;
    font-weight: 600;
    color: #007BFF;
    margin-right: 15px;
}

.edit-icons {
    display: flex;
    align-items: center;
    gap: 10px;
    position: absolute;
    top: 10px;
    right: 10px;
    font-size: 1.5em;
    transition: transform 0.3s ease-in-out;
}


.industry-card textarea {
    resize: none;
    overflow-y: auto;
    border: 1px solid #E5E5E5;
    border-radius: 15px;
    padding: 10px;
    transition: border 0.3s ease-in-out;
    height: 200px;
}

.industry-card textarea:focus {
    border: 1px solid #007BFF;
}


/* For larger tablets and smaller desktop screens */
@media (max-width: 1200px) {
    .industry-card {
        flex: 0 1 calc(33.33% - 40px);
        max-width: calc(33.33% - 40px);
        min-width: calc(33.33% - 40px);
    }
}

/* For tablets and smaller screens */
@media (max-width: 992px) {
    .industry-card {
        flex: 0 1 calc(50% - 40px);
        max-width: calc(50% - 40px);
        min-width: calc(50% - 40px);
    }
}

/* For mobile devices */
@media (max-width: 576px) {
    .industry-card {
        flex: 1 1 100%;
        max-width: 100%;
        min-width: 100%;
    }
}