.choose-container {
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    align-items: center;
    height: 70vh;
    background-color: #f9f9f9;
    padding: 5%;
}

.choose-container .card {
    width: 30%;
    height: auto;
    border-radius: 10px;
    background-color: #ffffff;
    box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.1);
    margin: 20px;
    padding: 30px;
    transition: transform 0.2s ease;
}

.choose-container .card:hover {
    transform: scale(1.05);
}

.choose-container .card .card-title {
    font-size: 24px;
    font-weight: 600;
    margin-bottom: 20px;
    color: #333333;
    text-align: center;
}

.choose-container .card .btn-primary {
    width: 100%;
    background-color: #007bff;
    border: none;
    color: white;
    padding: 10px;
    font-size: 18px;
    cursor: pointer;
    text-align: center;
    transition: background-color 0.3s ease;
}

.choose-container .card .btn-primary:hover {
    background-color: #0069d9;
}


/* Add a media query for screens smaller than 768px */
@media (max-width: 768px) {
    .choose-container {
        flex-direction: column;
        height: auto;
        padding: 2%;
    }

    .choose-container .card {
        width: 80%;  /* Adjust card width to fit smaller screens */
        margin: 10px auto;  /* Center the cards on smaller screens */
    }
}