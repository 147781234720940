.initialize-project-container {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    min-height: 100vh;  /* Adjust to the height of the viewport */
    padding: 20px;  /* Add some padding */
}



/* Add a media query for screens smaller than 768px */
@media (max-width: 768px) {
    .initialize-project-container {
        flex-direction: column;
        height: auto;
        padding: 2%;
    }
}
