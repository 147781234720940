.initialize-project-card {
    position: relative;
    display: flex;
    flex-direction: column;  
    justify-content: flex-start;
    align-items: flex-start;
    box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.3);
    transition: transform 0.2s ease;
    border-radius: 10px;
    margin: 2px;
    padding: 20px;  /* Increase padding */
    background-color: #ffffff;
    width: 100%;
    max-width: 1400px;  /* Increase max-width */
    overflow: hidden;
}

.form-and-chatbot-container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-start;
    width: 100%;
    flex-wrap: wrap; /* Enable wrapping for smaller screen sizes */
}


.form-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;

}

.start-project-button-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
}


.start-project-button {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 1rem;
    border-radius: 15px;  /* Increase border radius */
    box-shadow: 0 10px 20px rgba(0,0,0,0.1);  /* Add box shadow */
    transition: all 0.3s cubic-bezier(.25,.8,.25,1);
    background-color: #1e88e5;  /* Blue */
    color: white;
    width: 95%;
    height: 3rem;
}



.start-project-button:hover {
    background-color: #0d47a1;  /* Darker shade of blue on hover */
    box-shadow: 0 10px 20px rgba(0,0,0,0.2);  /* Button shadow increases */
}




.form-field {
    margin: 10px 0;
    background: #ffffff;
    margin: 1rem 0;
    padding: 1rem;
}

.form-field .MuiOutlinedInput-root {
    border-radius: 5px;
}

.form-field .MuiOutlinedInput-underline:after {
    border-bottom: 2px solid #1e88e5;
}

.form-field .MuiOutlinedInput-underline:before {
    border-bottom: none;
}

.form-field .MuiOutlinedInput-underline:hover:before {
    border-bottom: none;
}

.form-field .MuiOutlinedInput-multiline {
    padding: 10px;
}

.form-field .MuiFormHelperText-root {
    position: absolute;
    bottom: -20px;
}

.submit-button {
    width: 100%;
    color: white;
    background-color: #1e88e5;
    transition: background-color 0.3s; 
    height: 3rem;
    padding: 1rem;
}

.submit-button:hover {
    background-color: #0d47a1;
}

.submit-button .MuiButton-endIcon {
    margin-left: auto;
}




.initialize-project-card form {
    display: flex;
    flex-direction: column;
    width: 1400px;
}

.initialize-project-card .form-control {
    margin-bottom: 20px;
    width: 100%;
}

.initialize-project-card .form-control label {
    font-size: 22px; /* increased font size */
    font-weight: 700; /* increased font weight */
    margin-bottom: 20px;
    color: #333333;
}


.initialize-project-card .form-control input {
    font-size: 16px;
    padding: 40px;
    width: 100%;
}

.initialize-project-card .submit-btn {
    width: 100%;
    background-color: #007bff;
    border: none;
    color: white;
    padding: 10px;
    font-size: 18px;
    cursor: pointer;
    text-align: center;
    transition: background-color 0.3s ease;
}

.initialize-project-card .submit-btn:hover {
    background-color: #0069d9;
}

.assist-chatbot-container {
    display: flex;
    flex-direction: column;
    align-items: center;
}



#project-idea-input {
    justify-content: center;
    padding: 30px;
    border-radius: 10px;  /* Increase border radius */
    box-shadow: 0 10px 20px rgba(0,0,0,0.1);  /* Add box shadow */
    transition: all 0.3s cubic-bezier(.25,.8,.25,1);
}

.save-response-button {
    margin-top: 10px; 

}


@media (max-width: 768px) {
    .form-and-chatbot-container {
        flex-direction: column;
    }

    .form-container {
        margin-right: 0;
    }


}
