.deployment-summary {
    margin-top: 20px;
    padding: 20px;
    border-radius: 15px;
    background-color: #f8f9fa;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    border: 1px solid #e5e5e5;
    transition: background-color 0.3s ease, box-shadow 0.3s ease;
}

.deployment-summary h3 {
    font-size: 1.8em;
    color: #007BFF;
    margin-bottom: 15px;
    font-weight: 600;
}

.deployment-summary p {
    color: #555;
    font-size: 1.1em;
    line-height: 1.6;
    margin-bottom: 10px;
    padding-left: 10px;
    border-left: 4px solid #007BFF;
}

.deployment-summary a {
    color: #007bff;
    text-decoration: none;
    transition: color 0.2s ease;
}

.deployment-summary a:hover {
    color: #0056b3;
    text-decoration: underline;
}

.status-label {
    font-weight: bold;
    margin-right: 5px;
}

@media (max-width: 576px) {
    .deployment-summary p {
        font-size: 1em; /* Slightly smaller font on smaller screens */
    }
}

@media (min-width: 577px) and (max-width: 768px) {
    .deployment-summary p {
        font-size: 1.15em; /* Adjust font size for medium screens */
    }
}
