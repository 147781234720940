.home-container {
    display: flex;
    justify-content: center;
    align-items: flex-start; /* Align the card to the top */
    padding-top: 50px; /* Add some padding at the top to move the card a bit down */
    height: 100vh;
    background: #f9f9f9;
}

.home-card {
    width: 90%;
    max-width: 500px;
    border-radius: 15px; /* Rounded edges */
    box-shadow: 0px 10px 15px -3px rgba(0, 0, 0, 0.1), 0px 4px 6px -2px rgba(0, 0, 0, 0.05); /* Box shadow for a "lifted" look */
    background: #ffffff; /* White background */
    color: white; /* Text color */
}

.home-button {
    margin: 10px;
    padding: 10px 20px;
    border: none;
    color: white; /* Button text color */
    background: transparent; /* Transparent background */
}
