.document-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    padding: 10px;
    background-color: #FFFFFF; 
}

.document-card {
    flex: 1;
    max-width: 300px;
    min-width: 250px;
    margin: 20px;
    cursor: pointer;
    transition: background-color 0.5s ease-in-out, transform 0.5s ease-in-out, border 0.5s ease-in-out;
    border: 1px solid #E5E5E5;
    border-radius: 15px;
    padding: 20px;
    position: relative;
    box-shadow: 0 4px 8px rgba(0, 123, 255, 0.1);
    color: #007BFF;
}

.document-card.active, .document-card:hover {
    background-color: #F7FAFF; 
    box-shadow: 0 8px 16px rgba(0, 123, 255, 0.2);
    border: 1px solid #007BFF; 
    transform: scale(1.05);
}

/* Same styles for editing mode as in Idea.css */
.document-card input, .document-card textarea {
    width: 100%;
    border: none;
    outline: none;
    padding: 0;
    margin: 0;
    font-size: 16px;
    font-family: inherit;
    color: inherit;
    background-color: transparent;
    transition: border-bottom 0.3s ease-in-out;
    margin-bottom: 15px;
}



.document-card textarea {
    resize: none;
    overflow-y: auto;
    height: 200px;
    border: 1px solid #E5E5E5;
    border-radius: 15px;
    padding: 10px;
}

.card-header input {
    font-size: 18px;
    font-weight: 600;
    height: 60px;
}

.document-card .MuiSvgIcon-root {
    font-size: 1.0em;
    color: #007BFF;
}

.document-card.editing .MuiSvgIcon-root.saving {
    animation: rotate 1s infinite linear;
}

@keyframes rotate {
    from { transform: rotate(0deg); }
    to { transform: rotate(360deg); }
}

/* Hover effect for icons */
.document-card .MuiSvgIcon-root:hover {
    transform: scale(1.1);
    transition: transform 0.3s ease-in-out;
}

/* Transition for switching between edit/view mode */
.document-card input, .document-card textarea, .document-card h4, .document-card p {
    transition: opacity 0.5s ease-in-out;
}

.document-card.editing h4, .document-card.editing p {
    opacity: 0;
}

.document-card.editing input, .document-card.editing textarea {
    opacity: 1;
}


.document-card span {
    cursor: pointer;
    position: absolute;
    top: 10px;
    right: 10px;
    font-size: 1.5em;
    transition: all 0.3s ease-in-out;
}

.document-card span:hover {
    transform: scale(1.1)
}

.document-loading {
    font-size: 20px;
    color: #000000;
    text-align: center;
    padding: 20px;
}

.document-card p {
    font-size: 16px;
    line-height: 1.5;
    color: #333;
    margin-top: 10px;
}


.toast {
    position: fixed;
    bottom: 20px;
    left: 50%;
    transform: translateX(-50%);
    background-color: #28a745;
    color: white;
    padding: 10px 20px;
    border-radius: 5px;
    box-shadow: 0 2px 10px rgba(0,0,0,0.1);
    animation: fadeInOut 3s forwards;
}

@keyframes fadeInOut {
    0% { opacity: 0; transform: translateX(-50%) translateY(30px); }
    10% { opacity: 1; transform: translateX(-50%) translateY(0); }
    90% { opacity: 1; }
    100% { opacity: 0; transform: translateX(-50%) translateY(30px); }
}
