/* ViewProject.css */

.view-project {
    font-family: Arial, sans-serif;
    padding: 20px;
    margin: 0 auto;
    max-width: 90%;
}

.view-project-summary {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
}

/* Configuration Overview Section */
.config-overview {
    margin-top: 20px;
}



.configurations-container h3 {
    cursor: default;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 10px;
    border-radius: 5px;
    line-height: 1.5;
}

.config-card-container {
    padding-top: 10px;
}



/* Run Project Section */
.run-project-section {
    margin-top: 20px;
}

.run-project-card {
    display: flex;
    flex-direction: column;
}

.run-project-header {
    display: flex;
    align-items: center;
    cursor: pointer;
    padding: 5px 0;
}

.run-project-title {
    margin-left: 10px;
}

button:disabled {
    background-color: #95a5a6;
    cursor: not-allowed;
}

.run-project-step-counter {
    margin: 10px 0;
    font-weight: bold;
    align-items: center;
}


.deployment-section {
    margin-top: 20px;
}