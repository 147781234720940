/* ProjectSummary.css */

.project-summary-container {
    margin-top: 20px;
    padding: 20px;
    border-radius: 15px;
    transition: background-color 0.3s ease, box-shadow 0.3s ease, border 0.3s ease;
    margin-bottom: 20px;
    border: 1px solid #e5e5e5;
    width: 100%;
    position: relative; /* Added this line to make positioning the save icon easier */
}

.project-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 20px;  /* This will create space between the elements */
}


.project-name h2 {
    font-size: 2.0em;
    font-weight: 600;
    color: #007BFF;
    display: flex;
    align-items: center;
    cursor: pointer;
    transition: background-color 0.2s ease;
}



.project-name:hover {
    cursor: pointer;
    background-color: #f5f5f5;
    border-radius: 5px;
}





.project-progress {
    align-items: center;  /* Vertically center the progress bar */
    width: 45%;
    appearance: none;
    border: none;
    border-radius: 15px;
    align-items: right;
}




.project-progress::-webkit-progress-bar {
    background-color: #bdc3c7;
    border-radius: 15px;
}

.project-progress::-webkit-progress-value {
    background-color: #3498db;
    border-radius: 15px;
}

.progress-label {
    font-size: 1em;
    text-align: center;
}


.project-icon-container {
    margin-left: 10px;
    margin-right: 30px;
    display: flex;
    align-items: center;
    gap: 40px;  /* This will create space between the two icons */
}

.save-icon {
    cursor: pointer;    /* Make it look clickable */
    color: #007BFF;   /* Change this line */
}


.save-icon:hover {
    transform: scale(1.1);
    background-color: #f5f5f5;
    border-radius: 5px;
}



.project-icon-container :hover {
    cursor: pointer;
}


.project-icon {
    transition: transform 0.3s ease;
    cursor: pointer;
}

.project-icon:hover {
    transform: scale(1.1);
    background-color: #f5f5f5;
    border-radius: 5px;
}

button {
    background: #007BFF;
    color: white;
    padding: 5px 10px;
    border: none;
    border-radius: 4px;
    margin-top: 10px;
    cursor: pointer;
    transition: background-color 0.3s ease;
}

button:hover {
    background: #0056b3;
}


.project-summary-sidebar {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
}

.steps-modal-content-text p {
    padding-left: 25px; /* Increase spacing for the left border */
    border-color: #D1D1D1; /* Lighter blue for the border */
    border-left: #007BFF solid 5px; /* Add a left border */
    cursor: default;
    color: #B0B0B0;
    margin-right: 10px;
}


.project-name-input {
    font-size: 2.0em;
    font-weight: 600;
    color: #007BFF;
    background: transparent;
    border: none;
    outline: none;
    width: 100%;
    padding: 0;
    margin: 0;
}

.project-name-input:focus {
    box-shadow: none;
    border: none;
}


@keyframes rotate {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
}

.rotate-icon {
    animation: rotate 2s linear infinite;
}


@keyframes spin-half {
    to {
        transform: rotate(180deg);
    }
}


.spin-on-hover:hover {
    animation: spin-half 0.5s forwards;
}



/* Add some global styles for better responsiveness */
body, html {
    font-size: 16px; /* This sets the base font size to 16px */
}

/* Adjust the project summary container for larger screens */
.project-summary-container {
    max-width: 1200px; /* Prevent it from stretching too much on large screens */
    margin: 20px auto; /* Center the container horizontally */
}

/* Adjustments for small screens */
@media (max-width: 576px) {
    .project-name h2 {
        font-size: 1.5em; /* Reduce the font size for smaller screens */
    }

    .project-icon-container {
        flex-direction: column; /* Stack icons vertically */
        gap: 10px;  /* Adjust space between items */
    }

    .project-header {
        flex-direction: column; /* Stack items vertically */
        gap: 10px;  /* Adjust space between items */
    }

    .project-progress {
        width: 100%; /* Full width on smaller screens */
    }
}

/* Adjustments for medium screens */
@media (min-width: 577px) and (max-width: 768px) {
    .project-name h2 {
        font-size: 1.8em; /* Adjust font size for medium screens */
    }

    .project-icon-container {
        gap: 20px;  /* Adjust space between items */
    }
}