.status-message {
    display: flex;
    align-items: center;
    font-size: 1.1em;  
    font-weight: 400;  
    gap: 10px;
    padding: 10px;
}

.status-message:before {
    content: '→ '; 
}


