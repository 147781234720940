/* AssistChatBot.css */

.assist-chatbot-paper {
    padding: 10px;
    margin: 20px;
    width: 100%;
    border-radius: 15px; 
    transition: box-shadow 0.2s ease; /* added box-shadow transition */
    box-shadow: 0 10px 20px 0 rgba(0,0,0,0.1); /* softer shadow */
    justify-content: center;
    align-items: center;
}


.assist-chatbot-box {
    padding: 0px;
    border-radius: 15px;
    scrollbar-width: thin;
    scrollbar-color: #1e88e5 transparent;
    border-radius: 15px;
    border: #1e88e5 solid 1px; /* added border */

}

.assist-chatbot-box::-webkit-scrollbar {
    width: 5px;
}

.assist-chatbot-box::-webkit-scrollbar-thumb {
    background-color: #1e88e5;
}

.assist-chatbot-accordion {
    max-height: 60vh; /* Adjust based on your layout */
    overflow-y: auto;
    scrollbar-width: thin;
    scrollbar-color: #1e88e5 transparent;
    border-radius: 15px;
    margin: 10px 0;
    padding: 10px;
}

.assist-chatbot-accordion .MuiAccordionSummary-content {
    display: flex;
    justify-content: space-between;
    align-items: center;
    color: #000000;

}

.assist-chatbot-accordion .MuiSvgIcon-root {
    margin-left: 10px;
}

.assist-chatbot-accordion::-webkit-scrollbar {
    width: 5px;
}

.assist-chatbot-accordion::-webkit-scrollbar-thumb {
    background-color: #1e88e5;
}



.assist-chatbot-title {
    text-align: center;
    color: #000000;
    padding: 20px;
    font-weight: 700; /* Make it bold */
    font-size: 1.5em; /* Increase the size */
}

.assist-chatbot-subtitle {
    text-align: center;
    color: #888888; /* Change color to light gray */
    padding: 2px;
    font-weight: 400;
    font-size: 0.9em; /* Decrease the size */
}

.assist-chatbot-content {
    width: 100%; /* Control the width here */
    max-width: 1200px; /* Optionally set a max width */
    margin: 0 auto; /* Center the content */
}



.counter-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
}

.counter {
    flex: 1;
    text-align: left; /* Center the text */
    color: #777777; /* Use a more muted color */
    padding: 10px;
    font-weight: 500;  /* Slightly bolder than normal */
    font-size: 1.0em;  /* Slightly smaller than normal */
}








/* Styles for the Save response button */

.save-response-icon {
    color: #1e88e5;
}

.save-response-icon:hover {
    color: #0d47a1;
}



.MuiAccordion-root {
    margin: 10px 0;
    border: 1px solid rgba(0, 0, 0, .125);
    box-shadow: none;
    border-radius: 4px;
    &:not(:last-child) {
        border-bottom: none;
    }
    &:before {
        display: none;
    }
    &.Mui-expanded {
        margin: 10px 0;
    }
}


/* Styles for the Radio buttons */

.MuiRadio-root {
    color: #1e88e5;
}

.MuiRadio-root.Mui-checked {
    color: #1e88e5;
    transition: color 0.3s; 
}

/* Styles for the Question */

.question {
    padding: 10px;
    border-radius: 15px;
    font-weight: 400; /* Increase font weight */
    margin-bottom: 10px; /* Add some margin */
    transition: 0.3s ease; /* Add transition */
}

/* Styles for the Answer options */

.answer {
    display: flex;
    flex-direction: column;
    gap: 10px;  
    padding: 10px;
    border-radius: 5px;
    transition: all 0.3s ease;
}


.answer .MuiFormControlLabel-root {
    margin-left: 0px;  
    margin-right: 0px; 
}


.saved-response-box {
    border: 1px solid #1e88e5;
    border-radius: 15px;
    padding: 10px;
    margin-top: 10px;
    background-color: #e3f2fd;
}

.cb-assist-input-field {
    margin: 30px 0;
    padding: 15px 20px; /* Increase padding */
    background: #ffffff;
    border-radius: 15px;
    box-shadow: 0 0 8px rgba(0, 0, 0, 0.2);
}

.cb-assist-input-field .MuiFilledInput-root {
    background-color: #ffffff;
    border-radius: 5px;


}

.cb-assist-input-field .MuiFilledInput-underline:after {
    border-bottom: 2px solid #1e88e5;
}

.cb-assist-input-field .MuiFilledInput-underline:before {
    border-bottom: none;
}

.cb-assist-input-field .MuiFilledInput-underline:hover:before {
    border-bottom: none !important;
    color: #ffffff !important;
}


.cb-assist-input-field .MuiFilledInput-multiline {
    padding: 10px;
}


.cb-assist-input-field .MuiFormHelperText-root {
    position: absolute;
    bottom: -20px;
    color: #1e88e5;
}



.timestamp {
    font-size: 0.8em;
    color: rgba(0, 0, 0, 0.6);
}


/* Customize spinner here */
.MuiCircularProgress-colorSecondary {
    color: #1e88e5;
}





@media(min-width: 768px) and (max-width: 1023px) {
    .assist-chatbot-content {
        width: 85%; /* Adjust width for tablet screens */
    }

    .assist-chatbot-title {
        font-size: 1.8em; /* Increase the size for tablet screens */
    }

    .assist-chatbot-subtitle {
        font-size: 1.1em; /* Adjust the size for tablet screens */
    }

    .counter {
        font-size: 1.2em;  /* Adjust font size for tablet screens */
    }
}


@media(min-width: 1024px) {
    .assist-chatbot-content {
        width: 90%; /* Adjust width for desktop screens */
    }

    .assist-chatbot-title {
        font-size: 2em; /* Increase the size for desktop screens */
    }

    .assist-chatbot-subtitle {
        font-size: 1.2em; /* Adjust the size for desktop screens */
    }

    .counter {
        font-size: 1.4em;  /* Adjust font size for desktop screens */
    }
}


@media(min-width: 1200px) {
    .assist-chatbot-content {
        max-width: 1400px; /* Set a max width for very large screens */
        margin: 0 auto; /* Center the content */
    }
}


@media(max-width: 767px) {
    .assist-chatbot-content {
        width: 95%; /* Use more width for mobile screens */
        padding: 5px; /* Adjust padding for mobile */
    }

    .assist-chatbot-title {
        font-size: 1.2em; /* Adjust title size for mobile */
    }

    .assist-chatbot-subtitle {
        font-size: 0.8em; /* Adjust subtitle size for mobile */
    }

    .counter {
        font-size: 0.9em;  /* Adjust counter font size for mobile */
    }

    .question {
        padding: 8px; /* Reduce padding for mobile */
    }

    .answer .MuiFormControlLabel-root {
        margin-left: 0px;  /* Reduce margin for mobile */
        margin-right: 0px; 
    }
    
    .saved-response-box {
        margin-top: 5px; /* Adjust top margin for mobile */
        padding: 8px; /* Adjust padding for mobile */
    }
    
    .cb-assist-input-field {
        margin: 15px 0; /* Adjust margin for mobile */
    }
}


@media(max-width: 400px) {
    .assist-chatbot-title {
        font-size: 1.1em; /* Further reduce the title size for very small screens */
    }

    .assist-chatbot-subtitle {
        font-size: 0.75em; /* Further reduce subtitle size for very small screens */
    }

    .counter {
        font-size: 0.85em;  /* Further reduce counter font size for very small screens */
    }
}
