.project-card {
    overflow: hidden;
    position: relative;
    box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2);
    border-radius: 15px;
    height: 100%;
    width: 50%; 
    margin-top: 20px;  
    margin-bottom: 20px; 
    border-color: #ccc;  
    display: flex;
    flex-direction: column;
    justify-content: space-between; 
    cursor: pointer; 
    padding: 10px;
    transition: all 0.3s ease; /* smoother transition */
    padding: 20px; /* Increase padding for a more spacious look */
    background-color: #fafafa; /* Soft background color for a modern look */
    max-height: 120vh; /* Limit the height of the tabs content area */

}

.project-card.expanded {
    width: 100%;
    transition: all 0.3s ease-out;
    border-color: #007bff;
}

.project-title-container {
    display: flex;
    align-items: center;
    gap: 5px;
}

.project-sentence {
    font-size: 1.0em; /* smaller font for the description */
    color: #666; /* slightly dimmed color for contrast */
    max-width: 80%; /* wrap the text if it's too long */
    overflow: hidden;
    text-overflow: ellipsis;
}

.project-card .project-title-container {
    gap: 10px; /* Increase gap for a more spacious look */
}

.project-card .project-sentence {
    font-weight: 600; /* Regular font weight for a softer look */
}



.project-card:not(.expanded):hover {
    box-shadow: 0 8px 16px 0 rgba(0,0,0,0.2);
}


.project-card:hover {
    box-shadow: 0 2px 16px rgba(0,0,0,0.1);
}


.project-card.expanded {
    width: 100%;
    transition: all 0.3s ease-out;
    border-color: #007bff;  /* active border color */
}

.project-card.hovered {
    transform: scale(1.01);  /* subtle grow effect */
}

.project-code-chatbot-container {
    display: flex;
    width: 100%;
    gap: 10px;
    height: 100%;
}



.project-chatbot-container {
    flex-shrink: 1;
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    height: 100%;
    max-height: 120vh;
    overflow-y: auto;
    scrollbar-width: thin;
    cursor: default;
    border-radius: 15px;
    border: 1px solid #007bff;
    box-shadow: 0 5px 15px rgba(0,0,0,0.2);
}


/* Project Code Container Styling */
.project-code-container {
    flex-shrink: 0;
    flex-grow: 0;
    flex-direction: column;
    height: 100%; /* This will make them fill their parent's height */
    overflow-y: auto;
    cursor: default; /* Remove pointer cursor */
    border-radius: 10px; /* Increased border radius */
    padding: 1rem; /* Add padding for a spacious feel */
    background-color: #FFFFFF; /* White background for a clean look */
    border: 1px solid #e6e6e6; /* Light border */
    transition: all 0.3s ease; /* smooth transitions */
}




  .project-code-container, .project-chatbot-container {
    border: 2px solid #e9e9e9; /* Lighter border for a soft look */
}


  


.project-progress-bar,
.milestone-progress-bar {
    width: 100%; /* increase width */
    height: 40px; /* increase height */
    border-radius: 5px;
    margin-top: 10px; /* increase spacing */
}