.chatbot-paper {
    padding: 50px;
    margin: 40px;
    width: 100%;
    border-radius: 15px; /* Added border radius for consistency */
}

.chatbot-box {
    max-height: 70vh;
    overflow-y: scroll;
}

.message-box {
    display: inline-block;
    margin: 20px;
    padding: 10px;
    border-radius: 15px;
}

.user {
    text-align: right;
}

.user .message-box {
    background-color: #1e88e5;
    color: white;
}

.system {
    text-align: left;
}

.system .message-box {
    background-color: #e0e0e0;
    color: black;
}

.input-area {
    margin-top: 10px;
}

.submit-button {
    margin-left: 10px;
}
