/* JobOverviewCard.css */
.job-overview-card {
    transition: all 0.3s ease-out;
    box-shadow: 0 1px 8px rgba(0,0,0,0.1);
    border-radius: 10px;
    padding: 20px;
    background: #fff;
    display: flex;
    flex-direction: column;
    text-decoration: none;
    margin: 0 auto; /* adjusted margin */
    width: 100%; /* make it take full width of parent */
}


.job-overview-card:hover {
    box-shadow: 0 2px 16px rgba(0,0,0,0.1);
    transform: scale(1.02);
}

.job-overview-card-header {
    display: flex;
    align-items: center;
    gap: 10px;
    width: 100%;
    justify-content: flex-start;
}

.header-icon-group {
    display: flex;
    align-items: center;
    gap: 10px;
    margin-top: -10px;
}

.folder-icon {
    font-size: 25px;
    color: #007bff;
    fill: #007bff;
}
