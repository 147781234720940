/* BrainstormProgressBar.css */


.brainstorm-progress-bar {
    position: relative; 
    height: 25px;
    background-color: #E5E5E5;
    border-radius: 12px; /* Increased for a rounder look */
    overflow: hidden;
    justify-content: center;
    align-items: center;
    margin-bottom: 10px;
    box-shadow: 0px 3px 10px rgba(0, 0, 0, 0.1); /* New shadow for depth */
    cursor: default;
    margin-top: 10px;
}

.brainstorm-progress-fill {
    height: 100%;
    /* Gradient background for a more dynamic look */
    background: linear-gradient(to right, #33ADFF, #007BFF); 
    border-radius: 12px;
    transition: width 0.5s ease-in-out; /* Smooth transition */
}

.percentage-indicator {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    color: #F3F6FC;
    font-weight: 600; /* Bold font for clarity */
    z-index: 10;
    font-size: 0.9rem; /* Adjusted font size for better readability */
}

