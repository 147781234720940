.account-container {
    border-radius: 15px;
    background: #FFFFFF;
    padding: 1.5rem;
    transition: all 0.2s ease-in-out;
    max-width: 500px; /* Increased from 400px for a broader layout */
    margin: 0 auto;
    border: 1px solid #007BFF;
    display: flex;
    justify-content: space-between; /* To put the two sections side by side */
}

.section {
    width: 100%; /* Roughly half the container width, accounting for a small gap */
}

.section-title {
    text-align: center;
    margin-bottom: 20px;
    font-size: 1.5em; /* Decreased from 2em for better visual balance */
}

.account-title {
    text-align: center;
    margin-bottom: 20px;
    font-size: 2em;
    cursor: pointer;
}

.account-form .account-label {
    display: block;
    margin-bottom: 10px;
}

.account-form .account-input {
    margin-bottom: 20px;
    padding: 10px;
    font-size: 16px;
    border: none;
    border-radius: 5px;
    transition: background-color 0.2s ease-in-out;
}

.account-button {
    width: 100%;
    background-color: #007bff;
    border: none;
    color: white;
    padding: 10px;
    font-size: 1.5em;
    cursor: pointer;
    text-align: center;
    border-radius: 5px;
    transition: background-color 0.2s ease-in-out;
}

.account-button:hover {
    background-color: #0069d9;
}

.update-message {
    text-align: center;
    color: green;
    font-weight: bold;
    margin-top: 20px;
}


body {
    font-family: 'Arial', sans-serif;
    font-size: 16px;
    color: #333;
    background-color: #f5f5f5;
}

.account-container {
    box-shadow: 0 4px 12px rgba(0,0,0,0.05);
    border: none;
}

.section {
    background: #f9f9f9;
    border-radius: 8px;
    padding: 20px;
    box-shadow: 0 2px 6px rgba(0,0,0,0.05);
}

.account-form .account-label {
    font-weight: 600;
    color: #555;
}

.account-form .account-input {
    background: #fff;
    border: 1px solid #ddd;
    &:hover {
        border-color: #ccc;
    }
    &:focus {
        border-color: #007BFF;
        box-shadow: 0 0 5px rgba(0,123,255,0.25);
    }
}

.account-button {
    box-shadow: 0 2px 6px rgba(0,0,0,0.05);
    &:active {
        background-color: #0056b3;
    }
}

.update-message {
    color: #007BFF;
}



.snackbar {
    position: fixed;
    bottom: 20px;
    left: 50%;
    transform: translateX(-50%);
    padding: 10px 20px;
    background-color: #333;
    color: #fff;
    border-radius: 5px;
    z-index: 1000;
    font-size: 16px;
}

.snackbar.success {
    background-color: #4CAF50;
}

.snackbar.error {
    background-color: #f44336;
}

/* Animations for the snackbar */
@keyframes fadein {
    from { bottom: 0; opacity: 0; }
    to { bottom: 20px; opacity: 1; }
}

@keyframes fadeout {
    from { bottom: 20px; opacity: 1; }
    to { bottom: 0; opacity: 0; }
}

/* Spacing between sections */
.section + .section {
    margin-top: 30px;  /* Adjust as required */
}