.project-steps-container {
    border-radius: 5px;
    background: #FFFFFF; /* A more softer background color */
    transition: all 0.2s ease-in-out; /* For hover effect */
    max-height: 100vh; /* Set a max height */
    margin-top: 1rem; /* Add top margin */
  }

  .project-steps-scroll {
    max-height: 600px; /* Set a max height */
    overflow-y: auto; /* Enable vertical scrolling */
    scrollbar-width: thin; /* Scrollbar width for Firefox */
    scrollbar-color: #007BFF #F5F5F5; /* Scrollbar color for Firefox */
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 1rem;
    width: 100%;
  }
  
  /* Scrollbar styling for Chrome, Edge, and Safari */
  .project-steps-scroll::-webkit-scrollbar {
    width: 8px;
  }
  
  .project-steps-scroll::-webkit-scrollbar-track {
    background: #FFFFFF;
  }
  
  .project-steps-scroll::-webkit-scrollbar-thumb {
    background-color: #007BFF;
    border-radius: 20px;
  }
  
  
  
  .progress-bar-container {
    display: flex;
    flex-direction: column;
    align-items: center; /* Center the progress bar and title */
    margin: 1rem 0; /* Add top and bottom margin */
  }
  
  .progress-bar-container h5 {
    margin-bottom: 0.5rem; /* Add bottom margin to the title */
    font-size: 1em; /* Increase title size */
  }
  
  .step-progress-bar {
    width: 100%; /* increase width */
    height: 40px; /* increase height */
    border-radius: 5px;
    margin-top: 10px; /* increase spacing */
  }
  
  
  .project-step {
    border: none;
    margin-bottom: 20px;
    background-color: #FFFFFF;
    padding: 1rem;
    border-radius: 15px;
    transition: background-color 0.2s ease-in-out;
    border: 1px solid #007BFF;
    width: 100%;    
  }
  

.step-status-container {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 15px; /* Adjust as needed */
    margin-bottom: 10px;
  }
  

  .project-step-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;
    font-size: 1em; /* Increase font size */
    font-weight: 600; /* Bolder font */
    transition: color 0.2s ease-in-out; /* For hover effect */
    align-items: flex-start; /* Align the step number to the left */
  }
  
  .project-step-header:hover {
    color: #007BFF; /* Color change on hover */
  }
  
  .project-step-display {
    display: flex;
    align-items: center;
    gap: 10px; /* Increase gap */
  }
  
  .project-step-description {
    padding: 0rem; /* Increased padding */
    font-size: 1em; /* Increase font size */
    line-height: 1.75; /* Increase line height for better readability */
  }
  
  .current-step {
    border: 1px solid #007BFF; /* Add a border to the current step */
    box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2), 0 6px 20px 0 rgba(0,0,0,0.19); /* Add a shadow */
  }
  
  .step-number {
    font-size: 1.2em;
    font-weight: bold;
    margin-right: 5px;
  }
  

  .completed-step {
    background-color: #F5F5F5;
}

.completed-step .project-step-display, 
.completed-step .step-number, 
.completed-step .project-step-description {
    color: #28a745;
}




  .step-status {
    padding: 2px 5px;
    border-radius: 3px;
    font-size: 0.7em;
    text-transform: uppercase;
}

.step-status.complete {
    color: #fff;
    background-color: #28a745;
}

.step-status.incomplete {
    color: #fff;
    background-color: #dc3545;
}



  .checkbox-container {
    position: relative;
    padding-left: 40px;
    margin-bottom: 40px;
    cursor: pointer;
    font-size: 22px;
    user-select: none;
  }
  
  .checkbox-container input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
  }
  
  .checkmark {
    position: absolute;
    top: 0;
    left: 0;
    height: 25px;
    width: 25px;
    background-color: #eee;
    border-radius: 50%;
    border: 2px solid #28a745;
  }
  
  .checkbox-container:hover input ~ .checkmark {
    background-color: #fff;
  }
  
  .checkbox-container input:checked ~ .checkmark {
    background-color: #28a745;
  }
  
  .checkmark:after {
    content: "";
    position: absolute;
    display: none;
  }
  
  .checkbox-container input:checked ~ .checkmark:after {
    display: block;
  }
  
  .checkbox-container .checkmark:after {
    left: 9px;
    top: 5px;
    width: 5px;
    height: 10px;
    border: solid white;
    border-width: 0 3px 3px 0;
    transform: rotate(45deg);
  }
  