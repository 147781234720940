/* DeploymentCard.css */

.deployment-card {
    border: 1px solid #e0e0e0;
    padding: 15px;
    border-radius: 8px;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
    margin: 15px 0;
    position: relative;
    background-color: #fff;
    transition: box-shadow 0.3s ease, transform 0.3s ease;
    width: 100%;
    display: flex;
    align-items: start; /* Ensure content is top-aligned */
    min-height: 100px;
}

.deployment-card:hover {
    background-color: #F7FAFF; 
    box-shadow: 0 8px 16px rgba(0, 123, 255, 0.2);
    border: 1px solid #007BFF; 
    transform: translateY(-2px);
}

.deployment-card.in-progress {
    background-color: #F7FAFF;
}

.deployment-card.completed {
    background-color: #E8F5E9;
}

.deployment-card.not-started {
    background-color: #FFEBEE;
}

.deployment-icon {
    width: 50px;
    height: 50px;
    margin-right: 15px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-shrink: 0;
    flex-grow: 0;
}

.status-icon {
    width: 40px;
    height: 40px;
}

.completed-icon {
    color: green;
}

.in-progress-icon {
    color: #FFC107;
    width: 40px !important;
    height: 40px !important;
}

.not-started-icon {
    color: gray;
}

.deployment-content {
    padding: 10px;
    border-radius: 10px;
    line-height: 1.5;
}

.deployment-title {
    font-weight: bold;
    margin-bottom: 5px;
    font-size: 1.1em;
}

.deployment-message {
    margin-top: 5px;
    color: #555;
    font-size: 1em;
}

.deployment-timers {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    font-size: 0.9em;
    color: #555;
    gap: 4px;
    position: absolute;
    top: 10px;
    right: 10px;
    font-size: 14px;
}
