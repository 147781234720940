.loading-details-container {
    padding: 10px;
    background-color: #FFFFFF;
    align-items: center;
    margin-bottom: 40px;
}

.loading-details-message {
    font-size: 22px;
    color: #555;
    font-weight: 500;
    text-align: center;
    margin-bottom: 30px;
}




@keyframes fadeIn {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}

.loading-details-animation {
    margin-top: 20px;
}




.completed-steps-container {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    gap: 20px;
    padding: 0 40px;
}


.completed-step-card {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    gap: 20px;
    padding: 0 40px;
    min-width: 80%;
    max-width: 80%;
    border-radius: 15px;
    border: 1px solid #E5E5E5;
    transition: all 0.3s ease-in-out; /* Smooth transition for appearance */

}

.completed-step-card .step-completed {
    animation: fadeIn 0.3s;
}


.completed-step-card.pending {
    background-color: #FFEBEE; /* light red for pending tasks */
}

.completed-step-card.active, .completed-step-card:hover {
    background-color: #F7FAFF;
    box-shadow: 0 8px 16px rgba(0, 123, 255, 0.2);
    border: 1px solid #007BFF;

}


.step-complete {
    font-weight: bold;
    color: #007BFF;
    margin-left: 5px;
    font-size: 16px;
}





.step-number {
    background-color: #007BFF; /* Light green background */
    color: #ffffff; /* Green text color */
    border-radius: 50%;
    width: 30px;
    height: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 15px;
    font-weight: bold;
}


.completed-step-icon {
    width: 30px; 
    height: 30px;
    border-radius: 50%; 
    margin-right: 15px; 
    display: flex;
    align-items: center; 
    justify-content: center;
    font-weight: bold;
    background-color: #007BFF; /* Light green background */
    border: 2px solid #FFFFFF; /* White border */
}

.completed-step-icon .status-icon {
    color: #ffffff;
    width: 30px;
    height: 30px;
}


.step-content {
    flex-grow: 1;
    padding: 10px;
    border-radius: 10px;
    width: 80%;
}

.step-title {
    font-weight: bold;
    margin-bottom: 5px;
    color: #333; /* Darker text color for better readability */
}

.step-message {
    font-size: medium;
    color: #555; /* Slightly lighter text color */
}

@keyframes fadeIn {
    from {
        opacity: 0;
        transform: translateY(-10px);
    }
    to {
        opacity: 1;
        transform: translateY(0);
    }
}

/* Add responsive design for smaller screens */
@media (max-width: 768px) {
    .completed-steps-container {
        flex-direction: column;
    }

    .completed-step-card {
        width: 100%; /* Full width on small screens */
        margin-bottom: 10px; /* Add some space between cards */
    }
}


.step-counter {
    font-size: 16px;
    color: #007BFF;
    text-align: center;
    margin-bottom: 10px;
    font-weight: bold;
}



.project-ready-button {
    padding: 10px;
    border-radius: 10px; /* Rounded edges */
    cursor: pointer;
    transition: transform 0.3s, box-shadow 0.3s, background-color 0.3s;
    box-shadow: 0 4px 6px rgba(0, 123, 255, 0.1); /* Subtle shadow for depth */
    position: relative; /* Positioning context for the arrow icon */
    overflow: hidden; /* Keep things neat if we add any pseudo-elements */
    width: 30%;
    align-self: center;
    border: 1px solid #007BFF;
}

