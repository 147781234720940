/* ConfigCard.css */

.config-card {
    border: 1px solid #e0e0e0;
    padding: 15px;
    border-radius: 8px;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
    margin: 15px 0;
    position: relative;
    background-color: #fff;
    transition: box-shadow 0.3s ease, transform 0.3s ease;
}

.config-card:hover {

    border: 1px solid #e0e0e0;

}

.config-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;
    border-bottom: 1px solid #f0f0f0;
    padding-bottom: 10px;
    margin-bottom: 15px;
}



h4 {
    font-weight: 500;
    margin: 0;
    font-size: 16px;
    color: #333;
}

.info-icon {
    color: #999;
    font-size: 14px;
}

.chevron-icon {
    margin-right: 10px;
    transition: transform 0.3s ease-in-out;
    color: #007BFF;
}

.chevron-icon.rotated {
    transform: rotate(180deg);
}


.config-items {
    margin-top: 15px;
}

.config-save-btn {
    display: inline-block;
    background-color: #007bff;
    color: #fff;
    padding: 8px 15px;
    border-radius: 4px;
    border: none;
    cursor: pointer;
    transition: background-color 0.3s ease;
    margin-top: 15px;
}

.config-save-btn:hover {
    background-color: #0056b3;
}

.config-notification {
    position: relative;
    margin-top: 10px;
    padding: 8px 12px;
    border-radius: 4px;
    font-size: 14px;
}


.config-notification.success {
    background-color: #d4edda;
    color: #155724;
    border: 1px solid #c3e6cb;
}

.config-notification.error {
    background-color: #f8d7da;
    color: #721c24;
    border: 1px solid #f5c6cb;
}

.close-notification {
    position: absolute;
    top: 50%;
    right: 5px;
    transform: translateY(-50%);
    cursor: pointer;
    font-size: 16px;
}

.config-status-icon-active {
    color: green; 
    cursor: pointer;
    font-size: 20px;  /* Added this */
    margin-right: 20px;  /* Added this */

}

.config-status-icon-inactive {
    color: red;   
    cursor: pointer;
    font-size: 20px;  /* Added this */
    margin-right: 20px;  /* Added this */
}

