.project-overview-card {
    transition: all 0.3s ease-out;
    box-shadow: 0 1px 8px rgba(0,0,0,0.1);
    border-radius: 10px;
    padding: 20px;
    background: #fff;
    display: flex;
    flex-direction: column;
    text-decoration: none; /* remove underline */
    margin: 20px auto; /* center the card with automatic horizontal margin */
    max-width: 60rem; /* maximum width for larger screens */
}

.project-overview-card a {
    text-decoration: none;
    color: inherit;
}

.project-overview-card:hover {
    box-shadow: 0 2px 16px rgba(0,0,0,0.1);
    transform: scale(1.02); /* slightly increase size on hover */
}

.file-progress-bar,
.milestone-progress-bar {
    width: 100%; /* increase width */
    height: 40px; /* increase height */
    border-radius: 5px;
    margin-top: 10px; /* increase spacing */
}

.card-body {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 20px; /* increase spacing */
}

.project-overview-card-header {
    display: flex;
    align-items: center;
    gap: 10px; /* spacing between icon and title */
    width: 100%;
    justify-content: flex-start; /* align icon to the right */
}

.header-icon-group {
    display: flex;
    align-items: center;
    gap: 10px; /* adjust space between icons */
    margin-top: -10px;
}

.folder-icon {
    font-size: 25px;
    color: #007bff; /* change color to blue */
    fill: #007bff; /* change the inside color to white */
}


.progress-section {
    width: 100%; /* ensures the title and progress bar don't overlap */
}

.progress-title {
    font-weight: 500; /* slightly bolder text */
}
